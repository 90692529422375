import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IReturnCanisterValidityResult } from "../../../interfaces/IReturn";
import EndSession from "./EndSession";
import Button from "../../../components/Button";

const ReturnThankYou = () => {
    const navigate = useNavigate();
    const canisterResult = useLocation();
    const validityResult = canisterResult?.state as IReturnCanisterValidityResult;
    const [isReturnedAll, setIsReturnedAll] = useState<boolean>(validityResult?.returnedItemCount + 1 === validityResult?.itemTotalCount);

    useEffect(() => {
        document.body.className = "main-body main-content-body thank-body";
        window.scrollTo(0, 0);
    }, []);

    const buildNoticeDescription = () => {
        const state = validityResult?.canisterState?.toLowerCase() || 'normal';

        if (state === 'normal' && isReturnedAll) {
            return <DescriptionWithAllReturned />;
        }

        if (state === 'normal' && !isReturnedAll) {
            return <DescriptionWithPartReturned />;
        }

        if (state === 'used') {
            return <DescriptionWithUsedReturn />;
        }

        if (state === 'inshold') {
            return <DescriptionWithInsHoldReturned />;
        }
    }

    const getStyle = () => {
        const state = validityResult?.canisterState?.toLowerCase() || "normal";
        if (state === "inshold") {
            return { marginTop: "43px" };
        }

        return !isReturnedAll ? { marginTop: '65px' }: {};
    };

    return (
        <>
            {/*  start header section  */}
            <header className="bearSprayLogo thankLogo">
                <a href="#">
                    <img src="/assets/images/bearSpray-shack-logo.png" alt="logo" />
                </a>
            </header>
            {/*  end header section  */}

            {/*  start main content container  */}
            <section>
                <div className="thank-container main-content text-center" style={getStyle()}>
                    <div className="container">
                        {buildNoticeDescription()}

                        <div className="d-grid  uper-margin-thnx">
                            {
                                <Button className="btn btn-primary cst-submit text-uppercase" onClick={() => navigate("/return")}
                                    style={{ fontSize: "52px", padding: "34px 49px", marginBottom: "20px", visibility: isReturnedAll? 'hidden': 'visible' }}>
                                    RETURN ANOTHER CANISTER
                                </Button>
                            }
                        </div>
                        <EndSession />
                    </div>
                </div>
            </section >
            {/*  end main content container  */}
        </>
    );
};

const DescriptionWithAllReturned = () => {
    return (
        <>
            <h1 className="text-center main-text-color">THANK YOU!</h1>
            <p className="cfs-4 mt-para">
                <span>
                    Your bear spray rental has been successfully <br /> returned! A confirmation receipt
                    will be sent to <br /> the email address on file.
                </span>
            </p>
        </>
    );
}

const DescriptionWithPartReturned = () => {
    return (
        <>
            <h1 className="text-center main-text-color">THANK YOU!</h1>
            <p className="cfs-4 mt-para">
                <span>
                    Your bear spray canister was successfully received. To continue your return, please
                    select "Return Another Canister" or select "End Session" if you have no more items
                    to return.
                </span>
            </p>
        </>
    );
}

const DescriptionWithInsHoldReturned = () => {
    return (
        <>
            <h1 className="text-center main-text-color">Paw-sible Delay</h1>
            <p className="cfs-4">
                <span>
                    This canister needs to be manually reviewed, but no need to worry!
                    Sometimes our validation technology will flag an item for inspection just to be double safe.
                    <br /><br />
                    You will receive a follow up email within two weeks.<br />We appreciate your patience, thank you!
                </span>
            </p>
        </>
    );
}

const DescriptionWithUsedReturn = () => {
    return (
        <>
            <h1 className="text-center main-text-color">Uh oh!</h1>
            <p className="cfs-4">
                <span>
                    It was determined that the canister was deployed.
                    The Used and Returned rental rate will be charged to the card on file, and you will receive an email receipt.
                    <br /><br />
                    Thank you for your business and look forward to renting to you again!
                </span>
            </p>
        </>
    );
}


export default ReturnThankYou;
