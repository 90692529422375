import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Help from "../../components/Help";
import Modal from "../../components/Modal";
import { Initialization, IRentOrderAddCommand } from "../../interfaces/IRent";
import rentService from "../../services/rentService";
import { error as modalError } from "../../components/Modal";
import Loading from "../../components/Loading";
import Button from "../../components/Button";

const Rent = () => {
    const navigate = useNavigate();
    const [rentLoading, setRentLoading] = useState<boolean>(false);
    const [pricingDetailsVisible, setPricingDetailsVisible] = useState<boolean>(false);
    const [outOfStockVisible, setOutOfStockVisible] = useState<boolean>(false);
    const [init, setInit] = useState<Initialization>({ maxPurchaseQuantity: 1 });
    const [rentOrderAddCommand, setRentOrderAddCommand] = useState<IRentOrderAddCommand>({ quantity: 1 });

    useEffect(() => {
        document.body.className = "main-body pdp-body";
        window.scrollTo(0, 0);
        initialization();
    }, []);

    const initialization = async () => {
        const result = await rentService.initialization();
        if (result?.code === 200) {
            const data: Initialization = result?.value;
            if (data?.rentableDateRange && data?.rentableDateRange.length > 0) {
                setInit(data);
                const defaultReturnDate = data?.rentableDateRange && data?.rentableDateRange[0];
                const defaultPrice = data?.pricingList?.find((item) => item.days === 1)?.price || 0;
                const command = {
                    quantity: 1,
                    days: 1,
                    expectedReturnDate: defaultReturnDate,
                    price: defaultPrice,
                    total: 1 * 1 * defaultPrice,
                };
                setRentOrderAddCommand(command);
            } else {
                modalError(
                    "",
                    "Sorry, this location is closed for the season. Returns are still accepted but we are not processing new rentals.",
                    false
                );
            }
        }
    };

    const onReturnDateChange = (e: any) => {
        rentOrderAddCommand.expectedReturnDate = e.target.value;

        const days = (init?.rentableDateRange?.findIndex((item) => item === e.target.value) || 0) + 1;
        const price = init?.pricingList?.find((item) => item.days === days)?.price || 0;
        rentOrderAddCommand.total = rentOrderAddCommand.quantity * price;
        rentOrderAddCommand.days = days;
        rentOrderAddCommand.price = price;

        setRentOrderAddCommand({ ...rentOrderAddCommand });
    };

    const onQuantityChange = (e: any) => {
        const quantity = e.target.value;
        if (quantity >= 1 && quantity <= init?.maxPurchaseQuantity) {
            rentOrderAddCommand.quantity = quantity;
            rentOrderAddCommand.total = rentOrderAddCommand.quantity * (rentOrderAddCommand.price || 0);
            setRentOrderAddCommand({ ...rentOrderAddCommand });
        }
    };

    const onQuantityDecrease = () => {
        if (rentOrderAddCommand.quantity > 1) {
            rentOrderAddCommand.quantity--;
            rentOrderAddCommand.total = rentOrderAddCommand.quantity * (rentOrderAddCommand.price || 0);
            setRentOrderAddCommand({ ...rentOrderAddCommand });
        }
    };

    const onQuantityIncrease = () => {
        if (rentOrderAddCommand.quantity < init?.maxPurchaseQuantity) {
            rentOrderAddCommand.quantity++;
            rentOrderAddCommand.total = rentOrderAddCommand.quantity * (rentOrderAddCommand.price || 0);
            setRentOrderAddCommand({ ...rentOrderAddCommand });
        }
    };

    const onCheckInventory = async () => {
        const result: any = await rentService.inventory();
        if (result?.code === 200) {
            const inventoryResult = result?.value;
            if (rentOrderAddCommand.quantity <= inventoryResult) {
                delete rentOrderAddCommand.days;
                delete rentOrderAddCommand.price;
                delete rentOrderAddCommand.total;
                const orderResult = await rentService.placeAnOrder(rentOrderAddCommand);
                if (orderResult.orderId > 0) {
                    navigate("/age-verfication", {
                        state: orderResult,
                    });
                } else {
                    setOutOfStockVisible(true);
                    setRentLoading(false);
                }
            } else {
                setOutOfStockVisible(true);
                setRentLoading(false);
            }
        } else {
            setRentLoading(false);
        }
    };

    return (
        <>
            {/*  start header section  */}
            <header className="bearSprayLogo smallLogo">
                <a href="#">
                    <img src="/assets/images/logo-small.png" alt="bear-spray-logo" className="img-fluid small-logo" />
                </a>
                <nav>
                    <div className="cst-exit-btn">
                        <Button
                            className="btn btn-outline"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Exit
                        </Button>
                    </div>
                    <Help />
                </nav>
            </header>
            {/*  end header section  */}

            {/*  start main content container  */}
            <section>
                <div className="main-content pdp-container" style={{height:'1454px'}}>
                    <div className="container">
                        <h1 className="px- text-center">Guard Alaska Bear Spray Rental</h1>
                        <div style={{ marginTop: '-8px' }}>
                            <table className="table cst-table">
                                <thead>
                                    <tr>
                                        <th scope="row" style={{ width: '12.5%' }}>1 Days</th>
                                        <th scope="row" style={{ width: '12.5%' }}>2 Days</th>
                                        <th scope="row" style={{ width: '12.5%' }}>3 Days</th>
                                        <th scope="row" style={{ width: '12.5%' }}>4 Days</th>
                                        <th scope="row" style={{ width: '12.5%' }}>5+ Days</th>
                                        <th scope="row" style={{ width: '12.5%' }}>* Used or Damaged</th>
                                        <th scope="row" style={{ width: '12.5%' }}>Non-Return</th>
                                        <th scope="row" style={{ width: '12.5%' }}>Late Fee</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>$10</td>
                                        <td>$15</td>
                                        <td>$20</td>
                                        <td>$25</td>
                                        <td>$30</td>
                                        <td>$55</td>
                                        <td>$60</td>
                                        <td>$7/Day</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end mt-3">
                            <   div className="spraying-note cfs-2 fs-nunito-medium d-flex"><span>*</span> Please note that spraying the canister, no matter how little, will result in a Usage charge.</div>
                                <Button
                                    type="button"
                                    className="btn btn-danger cst-btn2 mt-0"
                                    onClick={() => {
                                        setPricingDetailsVisible(true);
                                    }}
                                >
                                    Click here for pricing details
                                </Button>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-3">
                                <div className="cst-img">
                                    <img src="/assets/images/bear-spray-new.png" alt="bear-spray" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-9 content-container">
                                <p className="mt-4">
                                    We rent Mace® brand Guard Alaska bear spray. Mace® is made in the USA and is the
                                    leader in self-defense pepper spray.
                                </p>
                                <p className="mb-0">Guard Alaska bear spray offers:</p>
                                <ul>
                                    <li>Up to 20 ft Range</li>
                                    <li>9 Seconds of Spray Time</li>
                                    <li>Power Fogger Design</li>
                                    <li>Bear Safe Formula</li>
                                    <li>EPA Registered</li>
                                </ul>
                            </div>
                        </div>
                        <div className="row bottom-container">
                            <div className="col-lg-12">
                                <div className="row ">
                                    <div className="col-lg-5 cst-input ">
                                        <label id="birthdaytime" className="mb-2">
                                            Select Your Return Date
                                        </label>
                                        <div className="styled-select" style={{ backgroundColor: '#ffffff', borderRadius: '20px' }}>
                                            <span className="cst-icon" style={{ zIndex: 0 }}>
                                                <i className="fa-solid fa-caret-down"></i>
                                            </span>
                                            <select
                                                className="cst-dropdown"
                                                style={{ width: "100%", backgroundColor: 'transparent', zIndex: 1, position: 'relative' }}
                                                value={rentOrderAddCommand.expectedReturnDate}
                                                onChange={onReturnDateChange}
                                            >
                                                {init?.rentableDateRange?.map((item, index) => {
                                                    const label = moment
                                                        .utc(item)
                                                        .utcOffset(new Date().getTimezoneOffset() / -60)
                                                        .format("ddd, MMM D");
                                                    return (
                                                        <option key={index} value={item}>
                                                            {label}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 text-center cst-input">
                                        <label className="mb-2">Return Time</label>
                                        <span id="cstTime" className="fs-1">
                                            {moment
                                                .utc(rentOrderAddCommand.expectedReturnDate)
                                                .utcOffset(new Date().getTimezoneOffset() / -60)
                                                .format("hh:mm A")}
                                        </span>
                                    </div>
                                    <div className="col-lg-4 cst-input text-center">
                                        <label className="form-label">
                                            Quantity (Max. {init?.maxPurchaseQuantity})
                                        </label>
                                        <div className="plus-minus-container">
                                            <Button
                                                type="button"
                                                className="btn minus-btn btn-active"
                                                onClick={onQuantityDecrease}
                                            >
                                                <i className="fa-solid fa-minus"></i>
                                            </Button>
                                            <input
                                                className="fw-size-medium cst-plus-minues"
                                                value={rentOrderAddCommand.quantity}
                                                onChange={onQuantityChange}
                                            />
                                            <Button
                                                type="button"
                                                className="btn plus-btn btn-active"
                                                onClick={onQuantityIncrease}
                                            >
                                                <i className="fa-solid fa-plus"></i>
                                            </Button>
                                        </div>
                                        <div className="d-flex justify-content-center display-5 main-text-color mt-3">
                                            <span>Total:</span>
                                            <span className="ms-3">${rentOrderAddCommand.total}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-light-color cst-input">
                                    <div className="d-flex align-items-center cst-text">
                                        <div className="me-3 mb-2">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="57.651"
                                                height="57.651"
                                                viewBox="0 0 57.651 57.651"
                                            >
                                                <path
                                                    id="Icon_metro-warning"
                                                    data-name="Icon metro-warning"
                                                    d="M32.4,7.9,56.555,56.05H8.238L32.4,7.9Zm0-5.223c-1.243,0-2.485.838-3.428,2.514L4.361,54.235c-1.885,3.352-.282,6.094,3.564,6.094H56.867c3.845,0,5.449-2.742,3.564-6.094h0L35.824,5.192C34.881,3.516,33.639,2.678,32.4,2.678ZM36,49.52a3.6,3.6,0,1,1-3.6-3.6A3.6,3.6,0,0,1,36,49.52Zm-3.6-7.206a3.6,3.6,0,0,1-3.6-3.6V27.9A3.6,3.6,0,0,1,36,27.9V38.71A3.6,3.6,0,0,1,32.4,42.313Z"
                                                    transform="translate(-3.57 -2.678)"
                                                    fill="#dba93b"
                                                />
                                            </svg>
                                        </div>
                                        <p className="cfs-2 fs-nunito-medium">
                                            DO NOT store where temperature may exceed 120°F/50°C (such as an enclosed
                                            vehicle). Doing so may cause product to discharge from the canister. Storing
                                            the canister below 32°F/0°C may cause a loss of pressure in the canister,
                                            reducing spray range.
                                        </p>
                                    </div>
                                </div>
                                <div className="d-grid bottom-margin-pdp">
                                    <Button
                                        className={`btn btn-primary-agree cst-submit text-uppercase ${init?.rentableDateRange ? "btn-primary" : "btn-gray"
                                            }`}
                                        onClick={() => {
                                            if (init?.rentableDateRange) {
                                                setRentLoading(true);
                                                !rentLoading && onCheckInventory();
                                            }
                                        }}
                                    >
                                        <Loading visible={rentLoading} />
                                        Rent Now
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end main content container  */}

            {/*  Modal for age */}
            <Modal visible={pricingDetailsVisible}>
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h1 className="text-uppercase">Pricing Details</h1>
                            <ul className="line">
                                <li className="secondary-text-color">
                                    Rental days are based on 24 hour periods (i.e. if you rent for 1 day at 8pm, you
                                    must return it by 8pm the following day)
                                </li>
                                <li className="secondary-text-color">
                                    Rentals not returned by the due date will be charged $7/day up to the non-return fee
                                    of $60*
                                </li>
                                <li className="secondary-text-color">
                                    Rentals that have been sprayed for any reason or damaged but returned will be
                                    charged $55*
                                </li>
                                <li className="secondary-text-color">Maximum rental period is 21 Days</li>
                                <li className="secondary-text-color">
                                    * No matter if your rental is late by multiple days, damaged, sprayed, or just plain
                                    not returned, you will never be charged more than $60 (+tax) total per canister of
                                    bear spray.
                                </li>
                            </ul>
                        </div>
                        <div className="modal-footer text-center">
                            <Button
                                type="button"
                                className="btn fs-nunito-medium text-uppercase"
                                onClick={() => setPricingDetailsVisible(false)}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal visible={outOfStockVisible}>
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h1 className="text-uppercase">out of stock</h1>
                            <p>
                                Sorry, this kiosk is out of stock. Inventory changes often so please check back later or
                                reserve online.
                            </p>
                        </div>
                        <div className="modal-footer text-center">
                            <Button
                                type="button"
                                className="btn fs-nunito-medium text-uppercase"
                                onClick={() => setOutOfStockVisible(false)}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Rent;
