import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { error as modalError } from "../components/Modal";
import config from "../config";

const customAxios = {
    instance: (url: string) => {
        const as = axios.create({
            baseURL: url,
            timeout: 60000,
        });

        as.interceptors.request.use(
            (request) => requestHandler(request),
            (error) => errorHandler(error)
        );

        as.interceptors.response.use(
            (response) => responseHandler(response),
            (error) => errorHandler(error)
        );
        return as;
    },
};

const requestHandler = (request: InternalAxiosRequestConfig) => {
    request.headers["X-Machine-ID"] = localStorage.getItem("machineId");
    request.headers["X-Location-ID"] = localStorage.getItem("locationId");
    return request;
};

const responseHandler = (response: AxiosResponse) => {
    if (response.status === 401 || response.status === 403) {
        window.location.href = "/";
    } else {
        return response?.data;
    }
};

const errorHandler = (error: any) => {
    if (error.code === "ERR_NETWORK") {
        //modalError(config.network.error.title, config.network.error.message);
        return { code: error.code };
    }
    if (error?.response?.status === 401 || error?.response?.status === 403) {
        window.location.href = "/";
        // } else if (error?.response?.status === 500) {
        //     const conf: any = config.network[500];
        //     const data = error?.response?.data || {};
        //     const messageCode = data["messageCode"] || data["MessageCode"];
        //     if (!conf[messageCode]) {
        //         const errorConf = conf.default;
        //         modalError(errorConf.title, errorConf.message, true);
        //     }
    } else {
        return error?.response?.data;
    }
};

export default customAxios.instance;
