/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Modal from "../Modal";
import Button from "../Button";

const Help = () => {
    const [helpPopupVisible, setHelpPopupVisible] = useState<boolean>(false);

    return (
        <>
            <a
                className="helper-wrapper-cst"
                onClick={() => {
                    setHelpPopupVisible(true);
                }}
                onTouchEnd={() => {
                    setHelpPopupVisible(true);
                }}
            >
                <span className="helper-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="69" viewBox="0 0 25 69">
                        <text
                            id="_"
                            data-name="?"
                            transform="translate(13 51)"
                            fill="#362517"
                            fontSize="50"
                            fontFamily="Nunito-Black, Nunito"
                            fontWeight="800"
                        >
                            <tspan x="-11.95" y="0">
                                ?
                            </tspan>
                        </text>
                    </svg>
                </span>
                <img src="/assets/images/icon-bg.png" alt="heloper icon" />
            </a>

            <Modal visible={helpPopupVisible}>
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h1 className="text-uppercase">Need Help?</h1>
                            <p>Visit our website at BearSprayShack.com or call 1-888-609-2327 for immediate assistance.</p>
                        </div>
                        <div className="modal-footer text-center">
                            <Button
                                type="button"
                                className="btn fs-nunito-medium text-uppercase"
                                onClick={() => {
                                    setHelpPopupVisible(false);
                                }}
                            >
                                Ok
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Help;
