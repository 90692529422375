import ClientDOM from "react-dom/client";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";
import reportWebVitals from "./reportWebVitals";
import { MQTTProvider } from "./mqtt";
import IdleNotice from "../src/components/Modal/ildeNotice";


const root = ClientDOM.createRoot(document.getElementById("root") as HTMLElement);
const router = createBrowserRouter(routes);

root.render(
    <>
        <MQTTProvider>
            <RouterProvider router={router} />
        </MQTTProvider>
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

declare global {
    interface Window {
        setMachineInformation: (machineId: string, locationId: string) => void;
        showIdleNotice: () => void;
    }
}

window.setMachineInformation = (machineId: string, locationId: string) => {
    localStorage.setItem("machineId", machineId);
    localStorage.setItem("locationId", locationId);
};

window.showIdleNotice = () => {
    if (!!!document.getElementById("idle_notice")) {
        const div = document.createElement("div");
        div.id = "idle_notice";
        ReactDOM.render(<IdleNotice onClose={() => document.body.removeChild(div)} />, div);
        document.body.appendChild(div);
    }
};
