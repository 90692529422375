import Button from "../Button";
import { useEffect, useState } from "react";
import debounce from "../../utils/debounce";
import { createRoot } from "react-dom/client";
interface IProps {
    title: string;
    message: string;
    onClose: (e?: any) => void;
}
const Alert = ({ title, message, onClose }: IProps) => {
    const div = document.createElement("div");
    const [toExitCountDown, setToExitCountDown] = useState<number>(10);
    useEffect(() => {
        debounce(() => {
            jumpIndexJob(1);
        }, 1000)();
    }, []);

    useEffect(() => {
        if (toExitCountDown === 0) {
            onClose();
        }
    }, [toExitCountDown]);

    const jumpIndexJob = (second: number) => {
        if(second<=0) return;
        setToExitCountDown(toExitCountDown - second);
        debounce(() => {
            jumpIndexJob(second + 1);
        }, 1000)();
    };
    return (
        <>
            <div className="modal-backdrop fade show"></div>
            <div
                className={"modal fade cstModal show"}
                tabIndex={-1}
                aria-labelledby="cstModal"
                style={{ display: "block" }}
                aria-modal="true"
                role="dialog"
            >
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h1 className="text-uppercase">{title}</h1>
                            <p dangerouslySetInnerHTML={{ __html: message?.replace(/\\n/g, "<br />") }}></p>
                        </div>
                        
                        <div className="modal-footer text-center">
                            <Button
                                type="button"
                                className="btn fs-nunito-medium text-uppercase"
                                onClick={onClose}
                            >
                                Close {toExitCountDown >= 0 ? `(${toExitCountDown})` : ""}
                            </Button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    );
};

const alert = (title: string, message: string) => {

    const div = document.createElement("div");
    const root = createRoot(div);

    const closeModal = ()=> {
        if(!div) return;
        div.remove();
        root.unmount();
    };

    root.render(
        <>
            <Alert title={title} message={message} onClose={() => closeModal()} />
        </>);
    document.body.appendChild(div);
};

export default alert;
