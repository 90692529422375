import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Help from "../../components/Help";
import { CANISTER_STATE, IReturnCanisterValidityResult } from "../../interfaces/IReturn";
import { IMQTTState, Topics, useMQTTController } from "../../mqtt";

const CanisterValidation = () => {
    const navigate = useNavigate();
    const canisterResult = useLocation();
    let validityResult = canisterResult?.state as IReturnCanisterValidityResult;

    const [controller, dispatch] = useMQTTController();

    useEffect(() => {
        document.body.className = "main-body";
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const { topic, message } = controller as IMQTTState;
        const instruct = message ? JSON.parse(message) : {};

        if (topic === Topics.CLIENT_CANISTER_RESULT) {
            validityResult.canisterState = instruct.CanisterState;
            (dispatch as React.Dispatch<any>)({ type: "clear" });
            if (!instruct.State && (instruct.CanisterState === CANISTER_STATE.MISS_CLIP || instruct.CanisterState === CANISTER_STATE.MISS_CLIP_REJECT)) {
                navigate("/missing-safety-clip", { state: validityResult });
            }
            return;
        }

        if (instruct.State && topic === Topics.CLIENT_RECYCLE_RESULT) {
            (dispatch as React.Dispatch<any>)({ type: "clear" });
            navigate("/return-thank-you", { state: validityResult });
        }
    }, [controller]);

    return (
        <>
            {/*  start header section */}
            <header className="bearSprayLogo smallLogo">
                <a href="#">
                    <img src="/assets/images/logo-small.png" alt="bear-spray-logo" className="img-fluid small-logo" />
                </a>
                <nav>
                    <Help />
                </nav>
            </header>
            {/*  end header section */}

            {/*  start main content container  */}
            <section>
                <div className="main-content" style={{ marginTop: '263px' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 content-container">
                                <h1 className="display-5 px-4 text-center canist-h1">
                                    Performing canister validation please wait
                                </h1>
                                <div className="text-center">
                                    <p>Process may take 1-2 minutes</p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="text-center cst-canister-vadtion-img">
                                    <img
                                        src="/assets/images/canister-validation.gif"
                                        alt="bear-spray"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end main content container */}
        </>
    );
};

export default CanisterValidation;
