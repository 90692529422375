import { useNavigate } from "react-router-dom";
import Help from "../../components/Help";
import Button from "../../components/Button";

const NotFound = () => {
    const navigate = useNavigate();

    return (
        <>
            {/*  start header section */}
            <header className="bearSprayLogo bigLogo">
                <a href="#">
                    <img src="/assets/images/logo.png" alt="logo" />
                </a>
                <nav>
                    <div className="cst-exit-btn">
                        <Button
                            className="btn btn-outline"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Home
                        </Button>
                    </div>
                    <Help />
                </nav>
            </header>
            {/* end header section */}

            {/*  start main content container  */}
            <section>
                <div className="main-content-container main-content">
                    <div className="container">
                        <div className="d-flex">
                            <div className="me-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="57.651"
                                    height="57.651"
                                    viewBox="0 0 57.651 57.651"
                                >
                                    <path
                                        id="Icon_metro-warning"
                                        data-name="Icon metro-warning"
                                        d="M32.4,7.9,56.555,56.05H8.238L32.4,7.9Zm0-5.223c-1.243,0-2.485.838-3.428,2.514L4.361,54.235c-1.885,3.352-.282,6.094,3.564,6.094H56.867c3.845,0,5.449-2.742,3.564-6.094h0L35.824,5.192C34.881,3.516,33.639,2.678,32.4,2.678ZM36,49.52a3.6,3.6,0,1,1-3.6-3.6A3.6,3.6,0,0,1,36,49.52Zm-3.6-7.206a3.6,3.6,0,0,1-3.6-3.6V27.9A3.6,3.6,0,0,1,36,27.9V38.71A3.6,3.6,0,0,1,32.4,42.313Z"
                                        transform="translate(-3.57 -2.678)"
                                        fill="#dba93b"
                                    />
                                </svg>
                            </div>
                            <h3 className="main-text-color">Not Found</h3>
                        </div>
                        <div className="row cst-row">
                            <Button
                                type="button"
                                className="btn btn-primary btn-border-radious"
                                style={{ visibility: "hidden" }}
                            >
                                Rent
                            </Button>
                            <Button
                                type="button"
                                className="btn btn-primary btn-border-radious"
                                style={{ visibility: "hidden" }}
                            >
                                Pickup
                            </Button>
                            <Button
                                type="button"
                                className="btn btn-primary btn-border-radious cst-return-mb"
                                style={{ visibility: "hidden" }}
                            >
                                Return
                            </Button>
                        </div>
                    </div>
                </div>
            </section>
            {/* end main content container */}
        </>
    );
};

export default NotFound;
