import { on } from "events";
import { useState } from "react";

interface IProps {
    id?:string;
    type?:"submit"|"button"|"reset";
    style?: React.CSSProperties;
    className?:string;
    disabled?:boolean|undefined;
    children?: React.ReactNode;
    onClick?: (e: any) => void;
}

const CustomInput = ({
    id,
    type,
    style,
    className,
    disabled,
    children,
    onClick
}: IProps) => {
    const [touched, setTouched] = useState<boolean>(false);
    return (
        <>
             <button
                id={id}
                type={type}
                className={className}
                style={style}
                disabled = {disabled}
                onClick={(e)=> {
                    if(!touched)
                    {
                        onClick && onClick(e);
                    }
                    setTouched(false);
                }}
                onTouchStart={(e)=> setTouched(true)}
                onTouchEnd={(e)=> {
                    if(!touched) return;
                    onClick && onClick(e);
                }}
                >
                {children}
            </button>
        </>
    );
};

export default CustomInput;
