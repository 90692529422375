import { useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Help from "../components/Help";
import Button from "../components/Button";
import { IMQTTState, Topics, useMQTTController } from "../mqtt";
import machineService from "../services/machineService";

const App = () => {
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const [note, setNote] = useState<string>("");
    const [stateValue, setStateValue] = useState<string>();
    const [controller] = useMQTTController();
    const updateState = (value: string|undefined|null)=>{
        if(!value) return;
        localStorage.setItem("machineState", value)
        setStateValue(value);
    };
    useEffect(() => {
        if(!stateValue) return;
        const state = JSON.parse(stateValue);
        const isDisabled = state.IsDisabled||state.isDisabled||false;
        const note = state.Note||state.note ||"The kiosk is under maintenance, visit our website at BearSprayShack.com or call 1-888-609-2327 for immediate assistance. Thanks!";
        setDisabled(isDisabled);
        setNote(note);

    },[stateValue]);

    useEffect(() => {
        const { topic, message} = controller as IMQTTState;
        if (topic !== Topics.CLIENT_MACHINE_STATE) return;
        updateState(message);
    }, [controller]);
    

    useEffect(() => {
        document.body.className = "main-body main-content-body";
        window.scrollTo(0, 0);
        initialization();
    }, []);

    const initialization = async () => {
        const defValue = localStorage.getItem("machineState");
        updateState(defValue);
        const result = await machineService.getState();
        if (result?.code !== 200) return;
        
        const data = result?.value;
        updateState(JSON.stringify(data));
    };

    const showMessage = (isDisabled: boolean, note: string) => {
        if(!isDisabled) return  <h3 className="main-text-color">
                                    Must be 18 or older to rent bear spray from this kiosk. <br /> ID or Passport
                                    verification will be required.
                                </h3>;
        return <h3 className="main-text-color">{note}</h3>
    };

    const showActions = (isDisabled: boolean) => {
        if(!isDisabled)
        {
            return <div className="row cst-row">
                        <Button
                            type="button"
                            className="btn btn-primary btn-border-radious"
                            onClick={() => {
                                navigate("/pdp");
                            }}
                        >
                            Rent
                        </Button>
                        <Button
                            type="button"
                            className="btn btn-primary btn-border-radious"
                            onClick={() => {
                                navigate("/pickup");
                            }}
                        >
                            Pickup
                        </Button>
                        <Button
                            type="button"
                            className="btn btn-primary btn-border-radious cst-return-mb"
                            onClick={() => {
                                navigate("/return");
                            }}
                        >
                            Return
                        </Button>
                    </div>
        }
        return <></>
    }

    return (
        <>
            {/*  start header section */}
            <header className="bearSprayLogo bigLogo">
                <a href="#">
                    <img src="/assets/images/logo.png" alt="logo" />
                </a>
                <nav>
                    <Help />
                </nav>
            </header>
            {/* end header section */}

            {/*  start main content container  */}
            <section>
                <div className="main-content-container main-content home-container">
                    <div className="container">
                        <div className="d-flex">
                            <div className="me-4">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="57.651"
                                    height="57.651"
                                    viewBox="0 0 57.651 57.651"
                                >
                                    <path
                                        id="Icon_metro-warning"
                                        data-name="Icon metro-warning"
                                        d="M32.4,7.9,56.555,56.05H8.238L32.4,7.9Zm0-5.223c-1.243,0-2.485.838-3.428,2.514L4.361,54.235c-1.885,3.352-.282,6.094,3.564,6.094H56.867c3.845,0,5.449-2.742,3.564-6.094h0L35.824,5.192C34.881,3.516,33.639,2.678,32.4,2.678ZM36,49.52a3.6,3.6,0,1,1-3.6-3.6A3.6,3.6,0,0,1,36,49.52Zm-3.6-7.206a3.6,3.6,0,0,1-3.6-3.6V27.9A3.6,3.6,0,0,1,36,27.9V38.71A3.6,3.6,0,0,1,32.4,42.313Z"
                                        transform="translate(-3.57 -2.678)"
                                        fill="#dba93b"
                                    />
                                </svg>
                            </div>
                            {showMessage(disabled, note)}
                        </div>
                       { showActions(disabled)}
                    </div>
                </div>
            </section>
            {/* end main content container */}
        </>
    );
};

export default App;
