export enum CANISTER_STATE {
    NORMAL_WEIGHT = "NormalWeight",
    OVERWEIGHT = "Over",
    OVERWEIGHT_REJECT = "OverReject",
    MISS_CLIP = "SCMiss",
    MISS_CLIP_REJECT = "MachClipReject",
}

export interface IReturnCanisterValidityResult {
    isValid: boolean;
    orderId: number;
    returnedItemCount: number;
    itemTotalCount: number;
    canisterID: string;
    canisterState?: string;
}
