import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../../config";
import debounce from "../../../utils/debounce";
import Button from "../../../components/Button";

const EndSession = () => {
    const navigate = useNavigate();

    const [toExitCountDown, setToExitCountDown] = useState<number>(config.thankyou.toExitCountDown);

    useEffect(() => {
        debounce(() => {
            jumpIndexJob(1);
        }, 1000)();
    }, []);

    useEffect(() => {
        if (toExitCountDown === 0) {
            navigate("/");
        }
    }, [toExitCountDown]);

    const jumpIndexJob = (second: number) => {
        if (second <= config.thankyou.toExitCountDown) {
            setToExitCountDown(config.thankyou.toExitCountDown - second);
            debounce(() => {
                jumpIndexJob(second + 1);
            }, 1000)();
        }
    };

    return (
        <>
            <div className="d-grid bottom-margin">
                <Button className="btn btn-primary cst-submit text-uppercase" onClick={() => {
                    setTimeout(() => {
                        navigate("/");
                    }, 0);
                }} >
                    END SESSION {toExitCountDown > 0 ? `(${toExitCountDown})` : ""}
                </Button>
            </div>
        </>
    );
};

export default EndSession;
