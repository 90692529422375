import ReactDOM from "react-dom";
import Button from "../Button";

const error = (title: string, message: string, retry?: boolean, retryCallback?: () => void) => {
    const div = document.createElement("div");
    ReactDOM.render(
        <>
            <div className="modal-backdrop fade show"></div>
            <div
                className={"modal fade cstModal show"}
                tabIndex={-1}
                aria-labelledby="cstModal"
                style={{ display: "block" }}
                aria-modal="true"
                role="dialog"
            >
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h1 className="text-uppercase">{title}</h1>
                            <p dangerouslySetInnerHTML={{ __html: message?.replace(/\\n/g, "<br />") }}></p>
                        </div>
                        {retry ? (
                            <div className="d-flex bg-prim justify-content-end cst-radious">
                                <Button
                                    type="button"
                                    className="btn fs-nunito-medium text-uppercase exit-btn"
                                    onClick={() => {
                                        document.location.href = "/";
                                    }}
                                >
                                    End Session
                                </Button>
                                <Button
                                    type="button"
                                    className="btn fs-nunito-medium text-uppercase btn-tAgain"
                                    onClick={() => {
                                        retryCallback && retryCallback();
                                        document.body.removeChild(div);
                                    }}
                                >
                                    Retry
                                </Button>
                            </div>
                        ) : (
                            <div className="modal-footer text-center">
                                <Button
                                    type="button"
                                    className="btn fs-nunito-medium text-uppercase"
                                    onClick={() => {
                                        document.location.href = "/";
                                    }}
                                >
                                    End Session
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>,
        div
    );
    document.body.appendChild(div);
};

export default error;
