import { createContext, useContext, useEffect, useMemo, useReducer } from "react";
import mqtt from "mqtt";
import config from "../config";
import { error as modalError, alert as modalAlert } from "../components/Modal";

const machineId = localStorage.getItem("machineId");

export const Topics = {
    CLIENT_CHARGE_RESULT: `client/${machineId}/charge/result`,
    CLIENT_DISPENSE_RESULT: `client/${machineId}/dispense/result`,
    CLIENT_CANISTER_RESULT: `client/${machineId}/canister/result`,
    CLIENT_RECYCLE_RESULT: `client/${machineId}/recycle/result`,
    CLIENT_ERROR: `client/${machineId}/error/result`,
    CLIENT_ERROR_DISPENSE: `client/${machineId}/error/dispense`,
    CLIENT_MACHINE_STATE: `client/${machineId}/machine/result`,
};

export interface IMQTTState {
    topic?: string;
    message?: string;
}

export const MQTTContext = createContext<(IMQTTState | React.Dispatch<any>)[]>([]);

export const MQTTProvider = ({ children }: any) => {
    const initialState: IMQTTState = {};
    const reducer = (state: any, action: any): IMQTTState => {
        return action.type === "message" ? { ...state, ...action.value } : {};
    };
    const [controller, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

    useEffect(() => {
        MQTTClient();

        setTimeout(() => {
            const machineId = localStorage.getItem("machineId") || "";
            const oldMachineId = localStorage.getItem("oldMachineId") || "";
            if (oldMachineId !== machineId) {
                localStorage.setItem("oldMachineId", machineId);
                window.location.reload();
            }
        }, 1000);
    }, []);

    const MQTTClient = () => {
        const options = {
            keepalive: 5,
            username: config.mqtt.username,
            password: config.mqtt.password,
            clientId: machineId + "website",
            reconnectPeriod: 0,
            resubscribe: true
        };

        const client = mqtt.connect(config.mqtt.brokerUrl, options);
        client.on("connect", (e) => {
            console.log("MQTT connected", e);
            client.subscribe({ [`client/${machineId}/+/result`]: { qos: 2 } }, function (err) {
                if (err) {
                    console.log("MQTT Subscribe Error: ", err);
                    client.reconnect();
                }
            });
        });

        client.on("message", (topic, message) => {
            console.log(`topic:${topic}`);
            if (topic === Topics.CLIENT_ERROR) {
                if(JSON.parse(message.toString())?.IsDispFail){
                    dispatch({ type: "message", value: { topic: Topics.CLIENT_ERROR_DISPENSE } });
                }

                return mqttError(topic, message);
            }

            dispatch({ type: "message", value: { topic, message: message.toString() } });
        });

        client.on("reconnect", () => {
            console.log("MQTT reconnected");
        });

        client.on("disconnect", (e) => {
            console.log("MQTT disconnected");
        });

        client.on("close", () => {
            console.log("MQTT closed");
            client.reconnect();
        });

        client.on("offline", () => {
            console.log("MQTT offline");
            client.reconnect();
        });
    };

    return <MQTTContext.Provider value={value}>{children}</MQTTContext.Provider>;
};

export const useMQTTController = () => {
    const context = useContext(MQTTContext);
    if (!context) {
        throw new Error("useMQTTController should be used inside the MQTTProvider.");
    }
    return context;
};

const mqttError = (topic: string, message: Buffer) => {
    const instruct = message ? JSON.parse(message.toString()) : {};
    if (instruct.MessageCode === "0001") {
        modalError("", instruct?.MessageContent, false);
    }
    if (instruct.MessageCode === "0002") {
        modalAlert("", instruct?.MessageContent);
    }
};
