import App from "../pages";
import Rent from "../pages/Rent";
import AgeVerfication from "../pages/Rent/AgeVerfication";
import LiabilityWaiver from "../pages/Rent/LiabilityWaiver";
import Payment from "../pages/Rent/Payment";
import ThankYou from "../pages/Rent/ThankYou";
import Pickup from "../pages/Pickup";
import AgeVerficationPickupFlow from "../pages/Pickup/AgeVerficationPickupFlow";
import Return from "../pages/Return";
import CanisterValidation from "../pages/Return/CanisterValidation";
import MissingSafetyClip from "../pages/Return/MissingSafetyClip";
import ReturnInstructions from "../pages/Return/ReturnInstructions";
import ReturnThankYou from "../pages/Return/ReturnThankYou";
import rentService from "../services/rentService";
import NotFound from "../pages/NotFound";

const routes = [
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/pdp",
        element: (<Rent />),
    },
    {
        path: "/pickup",
        element: (<Pickup />),
    },
    {
        path: "/return",
        element: (<Return />),
    },
    {
        path: "/age-verfication",
        element: (<AgeVerfication />),
    },
    {
        path: "/age-verfication-pickup-flow",
        element: (<AgeVerficationPickupFlow />),
    },
    {
        path: "/liability-waiver",
        element: (<LiabilityWaiver />),
    },
    {
        path: "/payment/:orderId",
        element: <Payment />,
        loader: rentService.getOrderDetail,
    },
    {
        path: "/thank-you",
        element: <ThankYou />,
    },
    {
        path: "/return-instructions",
        element: <ReturnInstructions />,
    },
    {
        path: "/missing-safety-clip",
        element: (<MissingSafetyClip />),
    },
    {
        path: "/canister-validation",
        element: <CanisterValidation />,
    },
    {
        path: "/return-thank-you",
        element: <ReturnThankYou />,
    },
    {
        path: "*",
        element: <NotFound />,
    },
];

export default routes;
