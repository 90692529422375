import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Help from "../../components/Help";
import Loading from "../../components/Loading";
import Modal from "../../components/Modal";
import rentService from "../../services/rentService";
import { openCamera, capture, recapture, colseCamera } from "../../utils/camera";
import Button from "../../components/Button";

const AgeVerfication = () => {
    const navigate = useNavigate();
    const orderResult = useLocation();

    const [cstModalVisible, setCstModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [uploadType, setUploadType] = useState<string>();

    useEffect(() => {
        document.body.className = "main-body";
        window.scrollTo(0, 0);
        sendIdOn();

        return () => {
            sendIdOff();
        }
    }, []);

    const sendIdOn = async () => {
       await rentService.sendIdOn();
    }

    const sendIdOff = async () => {
        await rentService.sendIdOff();
    }

    const onCaptureClick = async () => {
        setUploadType("Capture");
        setLoading(true);
        const { image, blob } = await capture(
            document.getElementById("video") as any,
            document.getElementById("canvas") as any
        );
        const canvasImg = document.getElementById("canvas-img") as any;
        canvasImg.src = image;
        canvasImg.onload = () => {
            canvasImg.style.display = "block";
        };
        const result = await rentService.ocrCustomer(blob);
        setUploadType("");
        setLoading(false);
        colseCamera();
        if (result && Object.keys(result).length > 0) {
            if (!result.isValid) {
                setCstModalVisible(true);
            } else {
                navigate("/liability-waiver", {
                    state: { ...orderResult.state, ...result },
                });
            }
        } else {
            recapture(document.getElementById("canvas") as any);
            openCamera(document.getElementById("video") as any);
            canvasImg.style.display = "none";
        }
    };

    return (
        <>
            {/*  start header section  */}
            <header className="bearSprayLogo smallLogo">
                <a href="#">
                    <img src="/assets/images/logo-small.png" alt="bear-spray-logo" className="img-fluid small-logo" />
                </a>
                <nav>
                    <div className="cst-exit-btn">
                        <Button
                            className="btn btn-outline"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Exit
                        </Button>
                    </div>
                    <Help />
                </nav>
            </header>
            {/*  end header section  */}

            {/*  start main content container  */}
            <section>
                <div className="main-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 content-container">
                                <h1 className="display-5 px-4 text-center">Age Verification</h1>
                                <p className="mt-3">
                                    All customers must be 18 or older and must provide a valid government-issued ID or
                                    Passport to rent from Bear Spray Shack.
                                </p>
                                <p className="mb-3">Types of ID accepted are:</p>
                                <div className="cst-document-container">
                                    <div className="row">
                                        <div className="w-50">
                                            <img src="/assets/images/card-image.png" alt="heloper icon" />
                                            <ul className="mt-5">
                                                <li>US or Canadian Drivers License</li>
                                                <li>State or Provincial Issued ID Card</li>
                                                <li>Military ID w/ D.O.B.</li>
                                            </ul>
                                        </div>
                                        <div className="w-50 d-flex justify-content-end">
                                            <div>
                                                <img
                                                    src="/assets/images/passport.png"
                                                    alt="heloper icon"
                                                    className="passport-image"
                                                />
                                                <ul className="mt-3">
                                                    <li>Passport</li>
                                                    <li>Passport Card </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="mt-3 text-center w-75 mx-auto">
                                    If you do not have a valid form of identification, you can exit this transaction by
                                    pressing the "EXIT" button above.
                                </p>
                            </div>
                        </div>
                        <div className="text-center cst-age">
                            <h2 className="fs-nunito-medium mb-5">
                                Please Insert ID as Shown and Press the Scan Button
                            </h2>
                            <div style={{ display: "flex" }}>
                                <div style={{ flex: 1 }}>
                                    <img
                                        src="/assets/images/ID-Read-min.gif"
                                        className="img-fluid"
                                        style={{ height: "371px", borderRadius: "20px" }}
                                        alt="id scan"
                                        onLoad={() => {
                                            openCamera(document.getElementById("video") as any);
                                        }}
                                    />
                                </div>
                                <div style={{ flex: 1, position: "relative" }}>
                                    <div style={{width:400, height:300,position: "relative",marginTop:20}}>
                                        <video id="video" autoPlay  ></video>
                                        <canvas id="canvas" width={1600} height={1200}></canvas>
                                        <img id="canvas-img" alt="" width={400} height={300} />
                                        </div>
                                    <Button
                                        type="button"
                                        className="btn btn-primary btn-border-radious"
                                        style={{
                                            padding: 0,
                                            width: "280px",
                                            borderRadius: "12px",
                                            marginTop: "20px",
                                            fontSize: "50px",
                                        }}
                                        onClick={onCaptureClick}
                                        disabled={loading}
                                    >
                                        <Loading visible={loading && uploadType === "Capture"} />
                                        Scan
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end main content container  */}

            {/*  Modal for age */}
            <Modal visible={cstModalVisible}>
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body ">
                            <h1 className="text-uppercase mb-0">Sorry</h1>
                            <p>
                                You must be 18 years of age or older and provide an accepted form of government issued
                                ID to rent or pick-up from Bear Spray Shack.
                            </p>
                        </div>
                        <div className="d-flex bg-prim justify-content-end cst-radious">
                            <Button
                                type="button"
                                className="btn fs-nunito-medium text-uppercase exit-btn"
                                onClick={() => {
                                    navigate("/");
                                }}
                            >
                                Exit
                            </Button>
                            <Button
                                type="button"
                                className="btn fs-nunito-medium text-uppercase btn-tAgain"
                                onClick={() => {
                                    setCstModalVisible(false);
                                    recapture(document.getElementById("canvas") as any);
                                    openCamera(document.getElementById("video") as any);
                                    const canvasImg = document.getElementById("canvas-img") as any;
                                    canvasImg.style.display = "none";
                                }}
                            >
                                Try Again
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default AgeVerfication;
