import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IMQTTState, Topics, useMQTTController } from "../../../mqtt";
import EndSession from "./EndSession";
import Progress from "./Progress";

const ThankYou = () => {
    const orderResult = useLocation();
    const order = orderResult?.state;

    const [controller, dispatch] = useMQTTController();

    const [pickupSuccess, setPickupSuccess] = useState<boolean>(false);
    const [pickupFinish, setPickupFinish] = useState<boolean>(false);

    useEffect(() => {
        document.body.className = "main-body main-content-body thank-body";
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const { topic, message } = controller as IMQTTState;

        const instruct = message ? JSON.parse(message) : {};

        if (topic === Topics.CLIENT_ERROR_DISPENSE
            && instruct.MessageCode=='0001' ){
            setPickupFinish(true);
        }

        if (topic !== Topics.CLIENT_DISPENSE_RESULT) return;

        (dispatch as React.Dispatch<any>)({ type: "clear" });

        if (instruct.State) {
            setPickupSuccess(true);
            setPickupFinish(true);
        }
    }, [controller]);

    const getMarginTop = () => {
        const offset = pickupSuccess ? 57 : 0;
     
        return order?.expectedReturnDate ? { marginTop: (55 - offset)  + 'px' } : { marginTop: (115 - offset) + 'px' }
    }

    return (
        <>
            {/* start header section */}
            <header className="bearSprayLogo thankLogo">
                <a href="#">
                    <img src="/assets/images/bearSpray-shack-logo.png" alt="logo" />
                </a>
            </header>
            {/*  end header section  */}

            {/*  start main content container  */}
            <section>
                <div className="thank-container main-content text-center" style={getMarginTop()}>
                    <div className="container">
                        <h1 className="text-center main-text-color">THANK YOU!</h1>
                        <h2 className="cst-margin-h2">A receipt has been sent to your email</h2>
                        <div className="cst-tnx-container mb-0">
                            <h2 className="cst-retun-h2 secondary-text-color">
                                <span style={{ marginRight: "12px" }}>Please return to any location by: </span>
                                {order?.expectedReturnDate && moment.utc(order?.expectedReturnDate).utcOffset(new Date().getTimezoneOffset() / -60).format("h:mma on MMM D, YYYY")}
                            </h2>
                            <h3 className="cst-margin-h3 mb-0 secondary-text-color">
                                Rentals returned after this time
                                <br />
                                will be charged a $7 per day late fee.
                            </h3>
                        </div>
                        <p className="mt-2">
                            <span style={{ marginRight: "8px" }}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="29.75"
                                    height="24.704"
                                    viewBox="0 0 29.75 24.704"
                                >
                                    <g
                                        id="Icon_feather-camera"
                                        data-name="Icon feather-camera"
                                        transform="translate(-1.083 -5.25)"
                                    >
                                        <path
                                            id="Path_69"
                                            data-name="Path 69"
                                            d="M29.833,26.432a2.523,2.523,0,0,1-2.523,2.523H4.606a2.523,2.523,0,0,1-2.523-2.523V12.557a2.523,2.523,0,0,1,2.523-2.523H9.651L12.174,6.25h7.568l2.523,3.784h5.045a2.523,2.523,0,0,1,2.523,2.523Z"
                                            fill="none"
                                            stroke="#dba93b"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                        <path
                                            id="Path_70"
                                            data-name="Path 70"
                                            d="M26.758,23.8a5.045,5.045,0,1,1-5.045-5.045A5.045,5.045,0,0,1,26.758,23.8Z"
                                            transform="translate(-5.754 -4.932)"
                                            fill="none"
                                            stroke="#dba93b"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                        />
                                    </g>
                                </svg>
                            </span>
                            Take a photo so you don't forget to return on time
                        </p>
                        <h2 className="cmt-tnx fs-nunito-medium" style={{ visibility: pickupFinish ? 'hidden': 'visible' }}>
                            Now Dispensing Rental{order?.itemQuantity > 1 && "s"}
                        </h2>
                        <p style={{ display: pickupFinish ? 'none' : 'inline-block' }}>May take up to 90 seconds{order?.itemQuantity > 1 && " per item"}.</p>
                        <div className="cst-progress-bar">{pickupSuccess ? <EndSession /> : <Progress />}</div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ThankYou;
