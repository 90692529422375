import { useEffect, useState } from "react";
import debounce from "../../../utils/debounce";

const Progress = () => {
    const [progress, setProgress] = useState<number>(0);

    useEffect(() => {
        debounce(() => {
            calcProgress(progress);
        }, 2000)();
    }, []);

    useEffect(() => {
        if (progress === 100) {
            debounce(() => {
                calcProgress(0);
            }, 100)();
        }
    }, [progress]);

    const calcProgress = (val: number) => {
        val++;
        setProgress(val);
        if (val < 100) {
            debounce(() => {
                calcProgress(val);
            }, 200)();
        }
    };

    return (
        <>
            <div className="progress ui-progressbar ui-corner-all ui-widget ui-widget-content" id="progressbar">
                <div className="progress-value progress-label"></div>
                <div
                    className="ui-progressbar-value ui-corner-left ui-widget-header"
                    style={{
                        width: `${progress === 0 ? 102 : (102 / 100) * progress}%`,
                    }}
                >
                    {progress === 0 && <div className="ui-progressbar-overlay"></div>}
                </div>
            </div>
        </>
    );
};

export default Progress;
