import axios from "./customAxios";
import { AxiosRequestConfig } from "axios";
import config from "../config";
import { error as modalError } from "../components/Modal";
import delay from "../utils/delay";

const retries = config.network.error.retries;
// const api = config.api;
const axiosInstance: any = axios(config.baseUrl);

const API = {
    get: async (url: string, conf?: AxiosRequestConfig) => {
        const result = await retry("get", url, conf);
        networkErrorModal(result);
        return result;
    },
    post: async (url: string, data?: any, conf?: AxiosRequestConfig) => {
        const result = await retry("post", url, data, conf);
        networkErrorModal(result);
        return result;
    },
    put: async (url: string, data?: any, conf?: AxiosRequestConfig) => {
        const result = await retry("put", url, data, conf);
        networkErrorModal(result);
        return result;
    },
};

const retry = async (name: string, ...params: any) => {
    let i = 0;
    let result: any;
    do {
        i > 0 && (await delay(1000));
        result = await axiosInstance[name](...params);
        i++;
    } while (i <= retries && result?.code === "ERR_NETWORK");
    return result;
};

const networkErrorModal = (result: any) => {
    const code: string = result?.code;
    if (code === "ERR_NETWORK") {
        modalError(config.network.error.title, config.network.error.message, true);
    } else if (Number(code) !== 200 && !result?.messageCode) {
        const conf: any = config.network[500];
        // const messageCode = result["messageCode"] || result["MessageCode"];
        const errorConf = conf.default;
        let message = errorConf.message;
        if(!!result?.message)
        {
            message = result?.message;
        }
        modalError(errorConf.title, message, true);
    } else if (Number(code) !== 200 && result?.messageCode) {
        switch (result?.messageCode) {
            case "7":
                modalError("PHONENUMBER INCORRECT", result?.message, true);
                break;
            case "13":
                modalError("LOCATION INCORRECT", result?.message, true);
                break;
            case "17":
                modalError("ORDER NOT FOUND", result?.message, true);
                break;
            case "20":
                modalError("SORRY", result?.message, true);
                break;
            default:
                modalError("", result?.message, true);
                break;
        }
    }
};

export { API };
