const config = {
    baseUrl: "http://localhost:5000/api",
    mqtt: {
        brokerUrl: "ws://localhost:5000/mqtt",
        username: "",
        password: "",
    },
    thankyou: {
        toExitCountDown: 30, // second
    },
    stillHere: {
        toExitCountDown: 60, // second
    },
    network: {
        error: {
            title: "Network error",
            message: "Network problem encountered, please try again or contact Bear Spray Shack at 1-888-609-2327.",
            retries: 0,
        },
        500: {
            default: {
                title: "Server error",
                message: "Oh Sorry! We seem to be having some issues, please try again or contact Bear Spray Shack at 1-888-609-2327.",
            },
            CanisterIsInvalid: {
                title: "Canister is invalid",
                message: "Canister is invalid, Please check the canister ID.",
            },
        },
    },
};

export default config;
