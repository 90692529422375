import { API } from "../api/httpClient";

const returnService = {
    checkCanisterValidity: async (canisterID: string) => {
        const result: any = await API.get(`/return/canister/validity?${canisterID ? `CanisterId=${canisterID}` : ''}`);
        return result;
    },

    getCanister: async (canisterID: string) => {
        const result: any = await API.get(`/return/canister?${canisterID ? `CanisterId=${canisterID}` : ''}`);
        return result;
    },

    returnCanisterContinue: async () => {
        const result: any = await API.post(`/return/canister/continue`);
        return result;
    },

    returnCanisterMissingSafetyClipExit: async () => {
        const result: any = await API.post(`/return/canister/exit`);
        return result;
    },
};

export default returnService;
