import React from "react";
import ReactDOM from "react-dom";
import error from "./error";
import alert from "./alert";

interface Props {
    visible: boolean;
    children?: React.ReactNode;
    className?: string;
}

const Modal = ({ visible, children, className }: Props) => {
    return visible ? (
        ReactDOM.createPortal(
            <>
                <div className="modal-backdrop fade show"></div>
                <div
                    className={className || "modal fade cstModal show"}
                    tabIndex={-1}
                    aria-labelledby="cstModal"
                    style={{ display: "block" }}
                    aria-modal="true"
                    role="dialog"
                >
                    {children}
                </div>
            </>,
            document.body
        )
    ) : (
        <></>
    );
};

export { error };
export { alert };
export default Modal;
