import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Help from "../../components/Help";
import { IRentCustomerAddCommand } from "../../interfaces/IRent";
import rentService from "../../services/rentService";
import Keyboard from "../../components/Keyboard";
import Loading from "../../components/Loading";
import moment from "moment";
import Button from "../../components/Button";

const LiabilityWaiver = () => {
    const navigate = useNavigate();
    const keyboard = useRef<any>();
    const orderResult = useLocation();
    const order = orderResult?.state;

    const [agreeLoading, setAgreeLoading] = useState<boolean>(false);
    const [keyboardVisible, setKeyboardVisible] = useState<boolean>(false);
    const [currentInput, setCurrentInput] = useState<string>();
    const [agreement, setAgreement] = useState<boolean>(false);
    const [rentCustomerAddCommand, setRentCustomerAddCommand] = useState<IRentCustomerAddCommand>({
        email: '',
        phoneNumberFormat: ''
    });

    useEffect(() => {
        document.body.className = "main-body";
        window.scrollTo(0, 0);
    }, []);

    const createCustomer = async (command: IRentCustomerAddCommand) => {
        command.firstName = order?.firstName;
        command.lastName = order?.lastName;
        const customer = await rentService.createCustomer(command);
        if (customer.customerId > 0) {
            const result = await rentService.updateOrder(order?.orderId, customer.customerId);
            navigate(`/payment/${order?.orderId}`)
        }

        setAgreeLoading(false);
    };

    const onAgreeClick = () => {
        if (agreement) {
            var regex =
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            rentCustomerAddCommand.emailError = !regex.test(String(rentCustomerAddCommand.email).toLowerCase());

            rentCustomerAddCommand.phoneNumberError =
                typeof rentCustomerAddCommand.phoneNumber === "undefined" || rentCustomerAddCommand.phoneNumber === "" || rentCustomerAddCommand.phoneNumber.length !== 10;

            setRentCustomerAddCommand({ ...rentCustomerAddCommand });

            if (!rentCustomerAddCommand.emailError && !rentCustomerAddCommand.phoneNumberError) {
                delete rentCustomerAddCommand.emailError;
                delete rentCustomerAddCommand.phoneNumberFormat;
                delete rentCustomerAddCommand.phoneNumberError;
                createCustomer(rentCustomerAddCommand);
            } else {
                setAgreeLoading(false);
            }
        }
    };

    const onPhoneNumberChange = (input: string) => {
        input = input.replace(/-/g, "");
        const keyboardInput = keyboard.current?.getInput() || "";
        let phone = keyboardInput;
        if (input.length > keyboardInput.length) {
            phone = keyboardInput + input.charAt(input.length - 1);
        } else if (input.length < keyboardInput.length) {
            phone = keyboardInput.substring(0, keyboardInput.length - 1);
        }

        if (phone.length <= 10) {
            keyboard.current?.setInput(phone);
            rentCustomerAddCommand.phoneNumber = phone;
            let newPhone = "";
            if (phone.length > 3) {
                newPhone += "***-";
                if (phone.length > 6) {
                    newPhone += "***-" + phone.substring(6);
                } else {
                    newPhone += phone.substring(3);
                }
            } else {
                newPhone = phone;
            }
            rentCustomerAddCommand.phoneNumberFormat = newPhone;
            setRentCustomerAddCommand({ ...rentCustomerAddCommand });
        }
    };

    const onKeyboardDelPhoneNumber = () => {
        const keyboardInput = keyboard.current?.getInput() || "";
        onPhoneNumberChange(keyboardInput.substring(0, keyboardInput.length - 1));
    };

    const onEmailChange = (email: string) => {
        rentCustomerAddCommand.email = email;
        keyboard.current?.setInput(email);
        setRentCustomerAddCommand({ ...rentCustomerAddCommand });
    };

    return (
        <>
            {/*  start header section  */}
            <header className="bearSprayLogo smallLogo">
                <a href="#">
                    <img src="/assets/images/logo-small.png" alt="bear-spray-logo" className="img-fluid small-logo" />
                </a>
                <nav>
                    <div className="cst-exit-btn">
                        <Button
                            className="btn btn-outline"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Exit
                        </Button>
                    </div>
                    <Help />
                </nav>
            </header>
            {/*  end header section  */}

            {/*  start main content container  */}
            <section>
                <div className="main-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 content-container">
                                <h1 className="display-5 px-4 text-center ">Liability Waiver</h1>
                                <p className="text-center mb-5 text-uppercase">* All Fields Required</p>
                                <div className="cst-scroll-area agreement-liability-waiver">
                                    <p>WAIVER AND RELEASE OF LIABILITY—BEARSPRAY SHACK LLC</p>
                                    <p>
                                        IN CONSIDERATION of being permitted to rent bear spray (“Spray”) from Bearspray
                                        Shack LLC, a Utah limited liability company (“Company”), its agents, affiliates,
                                        or partners, I, for myself, my personal representatives, executors, assigns,
                                        heirs, successors, and next of kin:
                                    </p>
                                    <p>
                                        I ACKNOWLEDGE, agree and represent that I am voluntarily renting and using the
                                        Spray provided by Company.
                                    </p>
                                    <p>
                                        I ACKNOWLEDGE, agree and represent that I have reviewed the tutorial video at
                                        https://bearsprayshack.com/rent-now, that I have read and reviewed the directions for safe use of the
                                        Spray, and that I understand how to use the Spray.
                                    </p>
                                    <p>
                                        I ACKNOWLEDGE, agree and represent that I will not intentionally spray myself or
                                        others with the Spray.
                                    </p>
                                    <p>
                                        I ACKNOWLEDGE, agree and represent that will act responsibly with respect to
                                        wildlife, including but not limited to bears.
                                    </p>
                                    <p>
                                        I ACKNOWLEDGE and FULLY UNDERSTAND that even when using the Spray properly,
                                        there is a possibility of injury due to malfunction, blow back, wind or
                                        inclement weather, or other unforeseen circumstances.
                                    </p>
                                    <p>
                                        I ACKNOWLEDGE and FULLY UNDERSTAND that whether using the Spray properly or
                                        improperly, in the event that the Spray comes into contact with my eyes, skin,
                                        or lungs, it may cause damage and/or irritation; that I may temporarily lose my
                                        eyesight; and that subsequent injury may take place if I attempt to move after
                                        spraying myself with the Spray.
                                    </p>
                                    <p>
                                        I ACKNOWLEDGE and FULLY UNDERSTAND that the act of utilizing or discharging the
                                        Spray is not a guarantee against bear attacks or the SERIOUS INJURY(IES) and/or
                                        DEATH that may result therefrom.
                                    </p>
                                    <p>
                                        I ACKNOWLEDGE and FULLY UNDERSTAND that: (a) being physically present in
                                        locations where wildlife, including but not limited to bears, may live involves
                                        risks and danger with a potential for SERIOUS INJURY or DEATH; (b) even when
                                        precautions are taken around such wildlife, animal attacks and encounters may
                                        still occur; (c) even when all precautions are taken to avoid a bear attack and
                                        I have used the Spray properly, I (or others I am with) may still be attacked by
                                        a bear; (d) there may be OTHER RISKS either not known to me or not readily
                                        foreseeable at this time; and I FULLY ACCEPT AND ASSUME ALL SUCH RISKS AND ALL
                                        RESPONSIBILITY FOR INJURY(IES), LOSSES, COSTS, AND DAMAGES I incur as a result
                                        of my use of the Spray.
                                    </p>
                                    <p>
                                        I HEREBY RELEASE, DISCHARGE, AND COVENANT NOT TO SUE COMPANY, their directors,
                                        officers, affiliates, employees, volunteers, representatives, and agents, (each
                                        considered one of the “Releasees” herein) FROM ALL LIABILITY, CLAIMS, DEMANDS,
                                        LOSSES, OR DAMAGES ON MY ACCOUNT CAUSED OR ALLEGED TO BE CAUSED IN WHOLE OR IN
                                        PART BY THE NEGLIGENCE OF THE “RELEASEES” OR OTHERWISE, INCLUDING NEGLIGENT
                                        MALFUNCTION OF THE SPRAY, SERIOUS INJURY/death, property damage, property theft,
                                        or actions of any kind which may hereafter accrue to me; I FURTHER AGREE that
                                        if, despite this RELEASE AND WAIVER OF LIABILITY, ASSUMPTION OF RISK, AND
                                        INDEMNITY AGREEMENT I, or anyone on my behalf, makes a claim against any of the
                                        Releasees, I WILL INDEMNIFY, SAVE, AND HOLD HARMLESS EACH OF THE RELEASEES from
                                        any litigation expenses, attorneys’ fees, loss, liability, damage, or cost which
                                        any may incur as a result of such claim; and I further agree to INDEMNIFY, SAVE,
                                        AND HOLD HARMLESS the Releasees from any and all liabilities or claims made by
                                        other individuals or entities as a result of any of my actions during my use of
                                        the Spray.
                                    </p>
                                    <p>
                                        I ACKNOWLEDGE that I have had the opportunity to review this Waiver and Release
                                        before executing this document and before renting the Spray. I acknowledge that
                                        this Waiver and Release of Liability will be used by the Company and affiliates,
                                        in which I may rent the Spray from and that it will govern my actions and
                                        responsibilities at said events.
                                    </p>
                                    <p>
                                        I expressly agree that this Waiver and Release of Liability is governed by the
                                        laws of State of Utah. I hereby expressly consent to the personal jurisdiction
                                        of the state and federal courts located in, or with jurisdiction covering, Salt
                                        Lake County, Utah, for any action or proceeding arising from or relating to this
                                        Waiver and Release of Liability, waive any argument that venue in any such forum
                                        is not convenient, and agree that any such action or proceeding shall only be
                                        venued in such courts.
                                    </p>
                                    <p>
                                        This Waiver and Release of Liability shall be construed broadly to provide a
                                        release and waiver to the maximum extent permissible under applicable law. I
                                        hereby certify that I have read this document; and, I understand its content.
                                    </p>
                                    <hr />
                                    <h6 className="fs-3 mb-3 fw-bold">
                                        <center>BEAR SPRAY SHACK LLC</center>
                                    </h6>
                                    <h6 className="fs-3 mb-3 fw-bold">
                                        <center>BEAR SPRAY RENTAL AGREEMENT</center>
                                    </h6>
                                    <p>
                                        This BEAR SPRAY Rental Agreement (this “<u>Agreement</u>”) is made and entered
                                        into this {moment().format("D \\d\\a\\y of MMMM, YYYY")} (the “Effective Date”)
                                        by and between BEAR SPRAY SHACK LLC, a Utah limited liability company (“
                                        <u>Company</u>”) and the undersigned, (“<u>Renter</u>”). Company and Renter are
                                        also referred to individually in this Agreement as a “Party” and collectively as
                                        the “Parties.”
                                    </p>
                                    <h6 className="fs-3 mb-3 fw-bold">
                                        <center>RECITALS:</center>
                                    </h6>
                                    <ol
                                        start={1}
                                        style={{
                                            marginBottom: "0in",
                                            listStyleType: "upper-alpha",
                                            marginLeft: "0.25in",
                                        }}
                                    >
                                        <li style={{ margin: 0 }}>
                                            WHEREAS<strong>,</strong> Company rents bear spray (“Spray”), and has the
                                            right to rent such Spray to Renter;
                                        </li>
                                        <li style={{ margin: 0 }}>
                                            WHEREAS<strong>,</strong> Renter wishes to rent from Company the Spray on
                                            the terms and conditions set forth in this Agreement; and
                                        </li>
                                        <li style={{ margin: 0, marginBottom: "1rem" }}>
                                            WHEREAS<strong>,</strong> Company wishes to rent the Spray on the terms and
                                            conditions set forth in this Agreement.
                                        </li>
                                    </ol>
                                    <p>
                                        NOW, THEREFORE, in consideration of the mutual covenants and obligations
                                        contained herein, and for other good and valuable consideration, the receipt and
                                        sufficiency of which is hereby acknowledged, the Parties hereby agree as
                                        follows:
                                    </p>
                                    <ol>
                                        <li>
                                            <u>Bear Spray Rental Pricing</u>.
                                        </li>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <strong>
                                                            <span>Days</span>
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        <strong>
                                                            <span>Price (excluding tax)</span>
                                                        </strong>
                                                    </td>
                                                    <td>
                                                        <strong>
                                                            <span>Damage Fee</span>
                                                        </strong>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>1 Day</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>$10</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>$45</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>2 Days</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>$15</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>$40</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>3 Days</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>$20</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>$35</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>4 Days</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>$25</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>$30</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>5 Days +</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>$30</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>$25</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>Canister Used and Returned</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>$55</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>N/A</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p>
                                                            <span>Canister Lost or Not Returned for any reason</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>$60</span>
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p>
                                                            <span>N/A</span>
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <li>
                                            <u>Rental Period</u>. The Rental Period shall begin the day the Spray is
                                            picked up from the agreed upon location and shall end when the Spray is
                                            returned to the point of origin or equivalent receiving point as designated
                                            by Company, except for rentals more than twenty-five (25) days.
                                        </li>
                                        <li>
                                            <u>Rental Charges</u>. The Renter shall pay all charges incurred for the
                                            Rental Period on the Spray rented at the rate stipulated herein.
                                        </li>
                                        <li>
                                            <u>Damages or Loss</u>. Renter accepts responsibility for all damage or
                                            loss, direct or indirect or consequential, however caused, during the entire
                                            Rental Period.
                                        </li>
                                        <li>
                                            <u>Payment</u>. All payments shall be made by credit or debit card. Upon
                                            rental of the Spray, Company shall charge Renter’s credit or debit card the
                                            amount owed for the Rental Period selected by Renter.. In the event Renter’s
                                            credit or debit card is declined after return of the Spray or after
                                            twenty-five (25) days from the first day of the Rental Period, Renter shall
                                            be liable for interest at 5% per annum for all amounts owed until payment is
                                            received by Company.
                                        </li>
                                        <li>
                                            <u>Damage or Use of Spray</u>. Renter agrees to return the Spray to Company
                                            in the same condition as when received by Renter, except where the Renter
                                            has used the Spray. Use or discharge of the Spray in any manner, whether
                                            intentional or not, or loss of the Spray, will result in Renter being
                                            charged the applicable Damage Fee. .
                                        </li>
                                        <li>
                                            <u>Early Return of Spray</u>. Renter shall be charged for the full Rental
                                            Period selected and shall not be entitled to a refund for returning the
                                            Spray before the Rental Period ends.
                                        </li>
                                        <li>
                                            <u>General Use of Spray</u>. Renter shall use the Spray only for its
                                            intended purpose, which is deterring bear attacks while located in the
                                            wilderness. At no time shall the Spray be intentionally subjected to
                                            improper, careless or needlessly adverse use or to any usage in violation of
                                            any statute, ordinance, rule, regulation or order of any government or other
                                            entity having jurisdiction over the place of use or while in transit. Renter
                                            further agrees: (a) to use the Spray in accordance with the manufacturer’s
                                            instructions; (b) to use the Spray in accordance with the tutorial video
                                            and/or instructions provided by Company; (c) to protect the Spray from all
                                            hazards, including adverse weather conditions or other environmental
                                            exposure; and (d) not to alter or modify the Spray.
                                        </li>
                                        <li>
                                            <u>Warranties</u>. Company warrants that it has the right to rent the Spray
                                            and that Renter shall have quiet possession of the Spray during the term of
                                            this Agreement.
                                        </li>
                                        <li>
                                            <u>Limits of Liability</u>. In consideration of Company ’s agreement to
                                            enter into this Agreement, Renter agrees as a limitation of liability that
                                            Company, its employees, affiliates and agents shall not be liable for
                                            direct, indirect or consequential loss, however caused, including but not
                                            limited to loss of use, loss of revenue and profit or other added costs
                                            resulting from Renter’s operation and use of the Spray. Company shall not be
                                            responsible for direct, indirect or consequential damages arising out of the
                                            operation of the Equipment, delay or loss of use of the Equipment for any
                                            reason, including but not limited to any act, failure to act or negligence
                                            of Company or any of its servants, agents, affiliates or employees.
                                        </li>
                                        <li>
                                            <u>Assignment</u>. Renter shall not assign this Agreement.
                                        </li>
                                        <li>
                                            <u>Successors and Assigns</u>. This Agreement shall be binding upon and
                                            shall inure to the benefit of the parties and their respective heirs,
                                            successors, administrators, executors, trustees, and assigns, subject to the
                                            assignment provisions herein.
                                        </li>
                                        <li>
                                            <u>Governing Law, Attorneys’ Fees</u>. The Parties hereby submit to the
                                            jurisdiction of the State of Utah in any action concerning this Agreement or
                                            its subject matter. Except as otherwise provided herein, the laws of the
                                            State of Utah shall govern such legal action. Venue shall lie exclusively in
                                            Salt Lake County, State of Utah. The prevailing party shall be entitled to
                                            reasonable attorneys’ fees and costs in any suit to enforce the terms of
                                            this Agreement.
                                        </li>
                                        <li>
                                            <u>Modification</u>. This Agreement may be modified only in a writing signed
                                            by the Parties.
                                        </li>
                                        <li>
                                            <u>Entire Agreement</u>. If any term or provision of this Agreement is held
                                            invalid, illegal or unenforceable in any respect for any reason, that
                                            invalidity, illegality or unenforceability shall not affect any other term
                                            or provision hereof, and this Agreement shall be interpreted and construed
                                            as if such term or provision, to the extent the same shall have been held to
                                            be invalid, illegal or unenforceable, had never been contained herein.
                                        </li>
                                        <li>
                                            <u>Waiver</u>. Failure by either Party to enforce any rights under this
                                            Agreement shall not be construed as a waiver of such rights nor shall a
                                            waiver by either Party in one or more instances be construed as constituting
                                            a continuing waiver or as a waiver in other instances.
                                        </li>
                                        <li>
                                            <u>Severability</u>. If any term or provision of this Agreement is held
                                            invalid, illegal or unenforceable in any respect for any reason, that
                                            invalidity, illegality or unenforceability shall not affect any other term
                                            or provision hereof, and this Agreement shall be interpreted and construed
                                            as if such term or provision, to the extent the same shall have been held to
                                            be invalid, illegal or unenforceable, had never been contained herein.
                                        </li>
                                        <li>
                                            <u>Counterparts</u>. This Agreement may be executed in two counterparts and
                                            each counterpart shall be deemed an original thereof.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="row cst-right-input requires-validation">
                                <div className="col-lg-12 cst-input">
                                    <label htmlFor="cstInputEmail" className="form-label">
                                        <span className="star">*</span>Email Address:
                                    </label>
                                    <input
                                        className={`form-control ${rentCustomerAddCommand.emailError && "field-error"}`}
                                        style={rentCustomerAddCommand.emailError ? { paddingTop: '15px', paddingBottom: '15px' }: {}}
                                        name="email"
                                        autoComplete="off"
                                        value={rentCustomerAddCommand.email}
                                        onClick={(e: any) => {
                                            keyboard.current?.setInput(rentCustomerAddCommand.email);
                                            setCurrentInput("email");
                                            setKeyboardVisible(true);
                                            setTimeout(() => {
                                                e.target.focus();
                                            }, 100);
                                        }}
                                        onChange={(e: any) => {
                                            onEmailChange(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            if (currentInput === "email") {
                                                e.target.focus();
                                                const pos = keyboard.current?.getCaretPosition();
                                                e.target.setSelectionRange(pos, pos);
                                            }
                                        }}
                                    />
                                    <div className="valid-feedback">Email field is valid!</div>
                                    <div className="invalid-feedback">Email field cannot be blank!</div>
                                </div>
                                <div className="col-lg-12 cst-input phone-input" style={{ position: "relative" }}>
                                    <label htmlFor="cstInputNumber" className="form-label">
                                        <span className="star">*</span>Phone Number:
                                    </label>
                                    <input
                                        className={`form-control ${rentCustomerAddCommand.phoneNumberError && "field-error"}`}
                                        style={rentCustomerAddCommand.emailError ? { paddingTop: '15px', paddingBottom: '15px' }: {}}
                                        id="cstInputNumber"
                                        autoComplete="off"
                                        value={rentCustomerAddCommand.phoneNumberFormat}
                                        onClick={(e: any) => {
                                            keyboard.current?.setInput(rentCustomerAddCommand.phoneNumber);
                                            setCurrentInput("phoneNumber");
                                            setKeyboardVisible(true);
                                            setTimeout(() => {
                                                e.target.focus();
                                            }, 100);
                                        }}
                                        onChange={(e) => {
                                            onPhoneNumberChange(e.target.value);
                                        }}
                                        onBlur={(e) => {
                                            if (currentInput === "phoneNumber") {
                                                const input = e.target.value;
                                                e.target.focus();
                                                const pos = keyboard.current?.getCaretPosition();
                                                const filPos = input.split("-").length - 1;
                                                e.target.setSelectionRange(pos + filPos, pos + filPos);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="col-lg-10 cmt-5 mx-auto">
                                    <label className="d-flex align-items-center">
                                        <span className="me-3">
                                            <div className="custom-control">
                                                <input
                                                    className="custom-check"
                                                    id="remember1"
                                                    type="checkbox"
                                                    onClick={(e: any) => {
                                                        setAgreement(e.target.checked);
                                                    }}
                                                />
                                                <label htmlFor="remember1" className="cfs-3 text-light-color">
                                                    <span className="star">*</span>By checking this box I agree to all
                                                    terms of the rental agreement and liability waiver
                                                </label>
                                            </div>
                                        </span>
                                    </label>
                                </div>
                                <div className="d-grid bottom-margin">
                                    <Button
                                        className={`btn btn-primary-agree cst-submit text-uppercase ${agreement ? "btn-primary" : "btn-gray"
                                            }`}
                                        onClick={() => {
                                            if (agreement) {
                                                setAgreeLoading(true);
                                                !agreeLoading && onAgreeClick();
                                            }
                                        }}
                                    >
                                        <Loading visible={agreeLoading} /> Agree to waiver
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end main content container  */}
            <Keyboard
                keyboardRef={(r) => (keyboard.current = r)}
                layout={currentInput === "phoneNumber" ? "numeric" : "default"}
                visible={keyboardVisible}
                onChange={(input: string) => {
                    currentInput === "phoneNumber" ? onPhoneNumberChange(input) : onEmailChange(input);
                }}
                onOk={() => {
                    setKeyboardVisible(false);
                }}
                onDel={() => {
                    currentInput === "phoneNumber" && onKeyboardDelPhoneNumber();
                }}
            />
        </>
    );
};

export default LiabilityWaiver;
