import { API } from "../api/httpClient";
import MockJs from "mockjs";
import {
    IRentCouponCommand,
    IRentCouponResult,
    IRentCustomer,
    IRentCustomerAddCommand,
    IRentOrderAddCommand,
    IRentPlaceAnOrderResult,
} from "../interfaces/IRent";

const rentService = {
    initialization: async () => {
        const result: any = await API.get("/Rent/page/initialization");
        return result;
    },
    inventory: async (): Promise<any> => {
        const result: any = await API.get(`/Rent/inventory`);
        return result;
    },
    placeAnOrder: async (rentOrderAddCommand: IRentOrderAddCommand): Promise<IRentPlaceAnOrderResult> => {
        const result: any = await API.post(`/Rent/order`, rentOrderAddCommand);
        return result?.code === 200 ? result?.value : {};
    },
    createCustomer: async (rentCustomerAddCommand: IRentCustomerAddCommand): Promise<IRentCustomer> => {
        const result: any = await API.post(`/customers`, rentCustomerAddCommand);
        return result?.code === 200 ? result?.value : {};
    },
    ocrCustomer: async (image: any) => {
        const formData = new FormData();
        formData.append("image", image);
        const result: any = await API.post(`/customers/ocr`, formData, { timeout: 5 * 60 * 1000 });
        return result?.code === 200 ? result?.value : {};
    },
    updateOrder: async (orderId: number, customerId: number): Promise<IRentPlaceAnOrderResult> => {
        const result: any = await API.put(`/Rent/order/${orderId}`, { orderId, customerId });
        return result?.code === 200 ? result?.value : {};
    },
    placeOrder: async (orderId: number): Promise<boolean> => {
        const result: any = await API.post(`/Rent/order/${orderId}/place-order`);
        return result?.code === 200 ? result?.value : false;
    },
    getOrderDetail: async ({ params }: any): Promise<IRentPlaceAnOrderResult> => {
        const result: any = await API.get(`/Rent/order/${params?.orderId}/detail`);
        return result?.code === 200 ? result?.value : {};
    },
    sendIdOn: async () => {
        const result: any = await API.post(`/Rent/id-on`);
        return result?.code === 200 ? result?.value : {};
    },
    sendIdOff: async () => {
        const result: any = await API.post(`/Rent/id-off`);
        return result?.code === 200 ? result?.value : {};
    },
    addCoupon: async (orderId: number, coupon: string) : Promise<IRentCouponResult> => {
        const result: any = await API.post(`/Rent/order/${orderId}/coupons`, {coupon:coupon});
        return result?.code === 200 ? result?.value : {isSuccess:false, orderId: orderId, message: "Add the coupon to EZRentOut unsuccessfully, please contact the administrator."};
    },
    cancelOrder: async (orderId: number) : Promise<boolean> => {
        const result: any = await API.post(`/Rent/order/${orderId}/cancel`);
        return result?.code === 200 ? result?.value : false;
    },
};

export default rentService;
