var mediaStreamTrack: MediaStream;
const videoWidth = 1600;
const videoHeight = 1200;

export const openCamera = async (video: HTMLVideoElement) => {
    try {
        const constraints = { video: { width: videoWidth, height: videoHeight } };
        const navigatorJs = navigator as any;
        if (navigatorJs.mediaDevices.getUserMedia) {
            mediaStreamTrack = await navigatorJs.mediaDevices.getUserMedia(constraints);
        } else if (navigatorJs.webkitGetUserMedia) {
            mediaStreamTrack = await navigatorJs.webkitGetUserMedia(constraints);
        } else if (navigatorJs.mozGetUserMedia) {
            mediaStreamTrack = await navigatorJs.mozGetUserMedia(constraints);
        } else if (navigatorJs.getUserMedia) {
            mediaStreamTrack = await navigatorJs.getUserMedia(constraints);
        } else {
            alert("Access to user media devices is not supported");
        }

        var CompatibleURL = window.URL || window.webkitURL;
        try {
            video.src = CompatibleURL.createObjectURL(mediaStreamTrack as any);
        } catch (e) {
            video.srcObject = mediaStreamTrack;
        }
        video.play();
    } catch (error) {
        console.log("Unable to access media devices", error);
    }
};

export const colseCamera = () => {
    if (mediaStreamTrack) {
        mediaStreamTrack.getVideoTracks().forEach((track) => {
            track.stop();
        });
    }
};

export const capture = (video: HTMLVideoElement, canvas: HTMLCanvasElement): Promise<any> => {
    return new Promise((resolve, reject) => {
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(video, 0, 0, videoWidth, videoHeight);
        const image = ctx?.canvas?.toDataURL("image/jpeg", 1);
        ctx?.canvas?.toBlob(
            (blob) => {
                resolve({ blob, image });
            },
            "image/jpeg",
            1
        );
    });
};

export const recapture = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d");
    ctx?.clearRect(0, 0, videoWidth, videoHeight);
};
