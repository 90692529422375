import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Help from "../../components/Help";
import Modal from "../../components/Modal";
import { CANISTER_STATE, IReturnCanisterValidityResult } from "../../interfaces/IReturn";
import { IMQTTState, Topics, useMQTTController } from "../../mqtt";
import returnService from "../../services/returnService";
import Loading from "../../components/Loading";
import debounce from '../../utils/debounce';
import Button from "../../components/Button";

const ReturnInstructions = () => {
    const navigate = useNavigate();
    const canisterResult = useLocation();
    const validityResult = canisterResult?.state as IReturnCanisterValidityResult;
    const [controller, dispatch] = useMQTTController();
    const [cstModalOverweightVisible, setCstModalOverweightVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [cstModalOverweightType, setCstModalOverweightType] = useState<string>();
    const [toCleanCountDown, setToCleanCountDown] = useState<number>(0);

    useEffect(() => {
        document.body.className = "main-body";
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const { topic, message } = controller as IMQTTState;
        const instruct = message ? JSON.parse(message) : {};
        if (topic === Topics.CLIENT_CANISTER_RESULT) {
            (dispatch as React.Dispatch<any>)({ type: "clear" });
            if (!instruct.State) {
                if (instruct.CanisterState === CANISTER_STATE.NORMAL_WEIGHT) {
                    validityResult.canisterState = instruct.CanisterState;
                    navigate("/canister-validation", {
                        state: validityResult,
                    });
                } else if (
                    instruct.CanisterState === CANISTER_STATE.OVERWEIGHT ||
                    instruct.CanisterState === CANISTER_STATE.OVERWEIGHT_REJECT
                ) {
                    setCstModalOverweightType(instruct.CanisterState);
                    setCstModalOverweightVisible(true);
                }
            }
        }
    }, [controller]);

    useEffect(() => {
        if(cstModalOverweightVisible && cstModalOverweightType === CANISTER_STATE.OVERWEIGHT){
            setToCleanCountDown(5);
            jumpIndexJob(0);
        }

    }, [cstModalOverweightVisible])

    const retry = async () => {
        const result = await returnService.checkCanisterValidity(validityResult?.canisterID);
        setLoading(false);
    };

    const cleanedTheCan = async () => {
        await returnService.returnCanisterContinue();
    };

    const jumpIndexJob = (second: number) => {
        if (second <= 5) {
            setToCleanCountDown(5 - second);
            debounce(() => {
                jumpIndexJob(second + 1);
            }, 1000)();
        }
    };

    return (
        <>
            {/*  start header section  */}
            <header className="bearSprayLogo smallLogo">
                <a href="#">
                    <img src="/assets/images/logo-small.png" alt="bear-spray-logo" className="img-fluid small-logo" />
                </a>
                <nav>
                    <div className="cst-exit-btn">
                        <Button
                            className="btn btn-outline"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Exit
                        </Button>
                    </div>
                    <Help />
                </nav>
            </header>
            {/*  end header section  */}

            {/*  start main content container  */}
            <section>
                <div className="main-content step-contianer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 content-container return-contianer">
                                <h1 className="px-4 text-center">Return Instructions</h1>

                                <div className="row main-cst-return">
                                    
                                    <div className="col-lg-12 px-5">
                                        <h3>
                                            Before placing the can in the kiosk, please check the following:
                                        </h3>
                                        <ul>
                                            <li>
                                                The can is clean and free of any extra items such as carabiners or
                                                string
                                            </li>
                                            <li>The orange safety clip is properly installed</li>
                                        </ul>
                                        <p>
                                            If both items of the checklist are good, then please place the can in the
                                            kiosk as shown and the return process will begin.
                                        </p>
                                        
                                    </div>
                                </div>
                                <div className="step-content" style={{ textAlign: "center", marginTop: "50px" }}>
                                    <h1>Place belt clip to the right</h1>
                                    <img
                                        src="/assets/images/Return-Can.gif"
                                        className="img-fluid"
                                        alt=""
                                        style={{
                                            width: "690px",
                                            height: "690px",
                                            borderRadius: "20px",
                                            marginTop:"30px"
                                        }}
                                    />
                                </div>
                                <div className="cst-content-return cst-content-bottom" style={{ marginTop: "34px" }}>
                                    <h3 className="text-center"  style={{ marginBottom: "0px" }}>Please wait for the door to open...</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*  end main content container  */}

            <div className="col-lg-12 keyboard">
                <div className="row number">
                    <Button className="col-lg-4" data-number="1">
                        1
                    </Button>
                    <Button className="col-lg-4" data-number="2">
                        2
                    </Button>
                    <Button className="col-lg-4" data-number="3">
                        3
                    </Button>
                    <Button className="col-lg-4" data-number="4">
                        4
                    </Button>
                    <Button className="col-lg-4" data-number="5">
                        5
                    </Button>
                    <Button className="col-lg-4" data-number="6">
                        6
                    </Button>
                    <Button className="col-lg-4" data-number="7">
                        7
                    </Button>
                    <Button className="col-lg-4" data-number="8">
                        8
                    </Button>
                    <Button className="col-lg-4" data-number="9">
                        9
                    </Button>
                    <Button className="col-lg-4 delete">Del</Button>
                    <Button className="col-lg-4" data-number="0">
                        0
                    </Button>
                    <Button className="col-lg-4 close-panel">ok</Button>
                </div>
            </div>

            <Modal visible={cstModalOverweightVisible}>
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h1 className="text-uppercase mb-0">Can is overweight</h1>
                            <p className="text-center">
                                For the safety of our kiosk and customers, we can not accept an overweight canister.
                            </p>
                        </div>
                        <div className="modal-footer text-center">
                            <Button
                                disabled={cstModalOverweightType === CANISTER_STATE.OVERWEIGHT && toCleanCountDown > 0}
                                type="button"
                                className="btn fs-nunito-medium text-uppercase"
                                onClick={() => {
                                    setCstModalOverweightType("");
                                    setCstModalOverweightVisible(false);
                                    cstModalOverweightType === CANISTER_STATE.OVERWEIGHT_REJECT && navigate("/");
                                    cstModalOverweightType === CANISTER_STATE.OVERWEIGHT && cleanedTheCan();
                                }}
                            >
                                {cstModalOverweightType === CANISTER_STATE.OVERWEIGHT_REJECT ? 
                                "Exit" : 
                                `I have cleaned the can ${toCleanCountDown > 0 ? `(${toCleanCountDown})` : ""}`}
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ReturnInstructions;
