import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Help from "../../components/Help";
import { CANISTER_STATE, IReturnCanisterValidityResult } from "../../interfaces/IReturn";
import returnService from "../../services/returnService";
import Button from "../../components/Button";

const MissingSafetyClip = () => {
    const navigate = useNavigate();
    const canisterResult = useLocation();
    const validityResult = canisterResult?.state as IReturnCanisterValidityResult;

    useEffect(() => {
        document.body.className = "main-body";
        window.scrollTo(0, 0);
    }, []);

    const retry = async (canisterID: string) => {
        const result = await returnService.getCanister(canisterID);
        if (result?.code === 200) {
            const newValidityResult = result?.value as IReturnCanisterValidityResult;
            newValidityResult.canisterID = validityResult.canisterID;
            if (newValidityResult?.isValid) {
                await returnService.returnCanisterContinue();
                navigate("/return-instructions", {
                    state: newValidityResult,
                });
            }
        }
    };

    const handleExitClick = async () => {
        await returnService.returnCanisterMissingSafetyClipExit();
        navigate("/");
    }

    return (
        <>
            {/*  start header section  */}
            <header className="bearSprayLogo smallLogo">
                <a href="#">
                    <img src="/assets/images/logo-small.png" alt="bear-spray-logo" className="img-fluid small-logo" />
                </a>
                <nav>
                    <div className="cst-exit-btn">
                        <Button
                            className="btn btn-outline"
                            onClick={() => {
                                navigate("/");
                            }}
                        >
                            Exit
                        </Button>
                    </div>
                    <Help />
                </nav>
            </header>
            {/*  end header section  */}

            {/*  start main content container  */}
            <section>
                <div className="main-content">
                    <div className="container">
                        <div className="row">
                            {validityResult.canisterState !== CANISTER_STATE.MISS_CLIP_REJECT && (
                                <div className="col-lg-12 content-container">
                                    <h1 className="display-5 px-4 text-center">missing safety clip</h1>
                                    <div className="row mt-5">
                                        <div className="col-lg-4">
                                            <div className="safty-image">
                                                <img
                                                    src="/assets/images/safety-clip.png"
                                                    className="img-fluid"
                                                    alt="safety clip"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-8">
                                            <p className="cfs-30">
                                                It appears the orange safety clip is missing or not properly installed.
                                                For safety reasons, the orange safety clip must be properly installed on
                                                the canister. Please see the picture for reference.
                                            </p>
                                            <p className="cfs-30">
                                                Once the clip is reinstalled, please click the button below to continue
                                                validation.
                                            </p>
                                        </div>
                                        <div className="d-grid try-btn">
                                            <Button
                                                id="submit"
                                                type="submit"
                                                className="btn btn-primary cst-submit text-uppercase"
                                                onClick={() => retry(validityResult?.canisterID)}
                                            >
                                                Try again
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <h1 className="text-center mb-5">What If I no longer have the safety Clip?</h1>
                            <p className="cfs-30">
                                Due to safety reasons, we cannot accept a canister that does not have the orange safety
                                clip properly installed. If you are unable to return your rental because of this reason,
                                a non-return fee will be charged to the original payment method and a receipt will be
                                sent to the email address on file within 14 days.
                            </p>
                            <p className="cfs-30">
                                Please dispose of the can properly following local guidelines for hazardous waste
                                disposal.
                            </p>
                            <div className="d-grid missing-btn">
                                <Button
                                    id="submit"
                                    type="submit"
                                    className="btn btn-primary cst-submit text-uppercase"
                                    onClick={handleExitClick}
                                >
                                    Exit
                                </Button>
                            </div>
                            {validityResult.canisterState === CANISTER_STATE.MISS_CLIP_REJECT && (
                                <div style={{ height: "651px" }}></div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {/*  end main content container  */}
        </>
    );
};

export default MissingSafetyClip;
