const debounce = (fn: (...args: any) => void, wait: number) => {
    let timer: any = null;
    return (...args: any) => {
        if (timer !== null) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn(args);
            timer = null;
        }, wait);
        return timer;
    };
};

export default debounce;
