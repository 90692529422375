import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import SimpleKeyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";

const keyboardLayout = {
    'default': [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{tab} q w e r t y u i o p [ ] \\',
        '{lock} a s d f g h j k l ; \' {enter}',
        '{shift} z x c v b n m , . / {shift}',
        '.com @ {space}'
    ],
    'shift': [
        '~ ! @ # $ % ^ &amp; * ( ) _ + {bksp}',
        '{tab} q w e r t y u i o p { } |',
        '{lock} a s d f g h j k l : " {enter}',
        '{SHIFT} z x c v b n m &lt; &gt; ? {SHIFT}',
        '.com @ {space}'
    ],
    'numeric': ["1 2 3", "4 5 6", "7 8 9", "{bksp} 0 {enter}"]
};

const newDisplayName = {
    "{bksp}": "Del",
    "{enter}": "OK",
    "{tab}": "Tab",
    "{lock}": "Caps",
    "{shift}": "Shift",
    "{SHIFT}": "SHIFT",
    "{space}": " "
};

interface IProps {
    layout: "numeric" | "default";
    visible: boolean;
    onChange: (input: string) => void;
    onOk: () => void;
    onDel?: () => void;
    keyboardRef: (r: any) => void;
}

const Keyboard = ({ layout, visible, onChange, onOk, onDel, keyboardRef }: IProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [layoutName, setLayoutName] = useState<string>(layout);
    const [documentClicked, setDocumentClicked] = useState<string>("");

    useEffect(() => {
        document.onclick = documentClick;
        document.ontouchend= documentClick;
    }, []);

    useEffect(() => setLayoutName(layout), [layout]);

    useEffect(() => setOpen(visible), [visible]);

    useEffect(() => {
        open && onOk && onOk();
    }, [documentClicked]);

    const documentClick = (e: any) => {
        if (e.target?.nodeName?.toLocaleLowerCase() !== "input") {
            setDocumentClicked(`${Math.random()}`);
        }
    };

    const onKeyPress = (button: string) => {
        switch (button) {
            case "{enter}": onOk && onOk(); break;
            case "{bksp}": onDel && onDel(); break;
            case "{shift}":
            case "{SHIFT}":
                setLayoutName((e) => e === "shift" ? layout : "shift");
                break;
        }
    };

    return ReactDOM.createPortal(
        <div style={{ display: open ? "block" : "none" }} className="hg-layout-dark-theme-layout" onClick={(e) => e.stopPropagation()} onTouchEnd={(e) => e.stopPropagation()} >
            <SimpleKeyboard
                keyboardRef={keyboardRef}
                theme="hg-theme-default hg-layout-numeric numeric-theme hg-layout-dark-theme"
                onChange={onChange}
                onKeyPress={onKeyPress}
                layout={{ ...keyboardLayout }}
                display={{ ...newDisplayName }}
                layoutName={layoutName}
            />
        </div>,
        document.body
    );
};

export default Keyboard;
