import { API } from "../api/httpClient";

const machineService = {
    getState: async (): Promise<any> => {
        const result: any = await API.get(`/machines/state`);
        return result;
    },
};

export default machineService;
