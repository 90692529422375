import { API } from "../api/httpClient";

const pickupService = {
    getOrderDetail: async (email: string): Promise<any> => {
        const result: any = await API.get(`/pickup/order`, { params: { email } });
        return result;
    },
    checkout: async (orderId: number): Promise<boolean> => {
        const result: any = await API.put(`/pickup/order/${orderId}/checkout`, { orderId });
        return result?.code === 200 ? result?.value : false;
    },
};

export default pickupService;
