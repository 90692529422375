import React, { useState, useEffect } from 'react';
import config from '../../config';
import debounce from "../../utils/debounce";
import Button from '../Button';

interface IProps {
    onClose: Function
}


const IdleNotice = ({ onClose }: IProps) => {
    const [toExitCountDown, setToExitCountDown] = useState<number>(config.stillHere.toExitCountDown);
    const [isContinue, setIsContinue] = useState<boolean>(false);
    useEffect(() => {
        debounce(() => {
            jumpIndexJob(1);
        }, 1000)();
    }, []);

    useEffect(() => {
        if (toExitCountDown === 0 && !isContinue) {
            document.location.href = "/"
        }
    }, [toExitCountDown,isContinue]);

    const jumpIndexJob = (second: number) => {
        if (second <= config.stillHere.toExitCountDown) {
            setToExitCountDown(config.stillHere.toExitCountDown - second);
            debounce(() => jumpIndexJob(second + 1), 1000)();
        }
    };
    return (
        <>
            <div className="modal-backdrop fade show"></div>
            <div className="modal fade cstModal show" tabIndex={-1} style={{ display: "block" }} aria-modal="true" role="dialog" >
                <div className="modal-dialog modal-md modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <h1 className="text-uppercase">Still Here?</h1>
                        </div>
                        <div className="d-flex bg-prim justify-content-end cst-radious">
                            <Button type="button" className="btn fs-nunito-medium text-uppercase exit-btn" onClick={() => document.location.href = "/"} >
                                End Session
                            </Button>
                            <Button type="button" className="btn fs-nunito-medium text-uppercase btn-tAgain" onClick={() =>{
                                setIsContinue(true);
                                onClose && onClose();
                            }} >
                                Continue {toExitCountDown > 0 ? `(${toExitCountDown})` : ""}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default IdleNotice;